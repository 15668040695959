var jsonSchemas = require("../../static/expectations-list.js").jsonSchemas;
var _ = require("lodash");

var regularParsing = function(section) {
    return section.replace('*', '').trim().replace(/ +/g, ' ');
};

var markdownLinkMatcher = /\[([^\[]+)\]\((.*?)\)/;

var parseField = function(string, parseSection) {
    if (!string) return [];
    var sections = string.split('\n');
    var sectionsWithoutTitle = sections.slice(1, sections.length);
    return sectionsWithoutTitle.map(parseSection || regularParsing).filter(section => Boolean(section));
};

var linkParsing = function(string) {
    var text = markdownLinkMatcher.exec(string);
    if (!text) return null;
    return {
        type: "link",
        title: text[1],
        url: text[2]
    };
};

var textParsing = function(string) {
    return {
        type: "text",
        content: regularParsing(string)
    };
};

var combinedParsing = function(section, match) {
    var subsections = section.split(match);
    subsections.splice(1, 0, match);
    return subsections.map(function(subsection) {
        return linkParsing(subsection) || textParsing(subsection);
    });
};

var parseTextWithLinks = function(string) {
    if (!string) return null
    var sections = string.split(/\n\n|\n/);
    return sections.map(function(section) {
        var text = markdownLinkMatcher.exec(section);
        return text ? combinedParsing(section, text[0]) : [textParsing(section)];
    });
};

const parseExampleData = function(string) {
    if (!string) return null
    const exampleData = string.split("Example Data:")
    const regexToDivideByTableName = /(?:test_table\S*\s)/g;
    const tables = exampleData[exampleData.length - 1].split(regexToDivideByTableName).map(table => table.trim()).filter(table => table);

    return tables.map(table => {
        const lines = table.split(/\n/);
        const header = lines[0].trim().split(/ {2,}/).map(function(headerItem) {
            return headerItem.replace(/"/g, '');
        });
        const data = lines.slice(1, lines.length).map(function(row) {
            return row.trim().split(/ +/);
        });
        return {
            header: header,
            data: data.map(function(row) {
                return row.slice(1, row.length);
            })
        }
    })
};

const removeExtraSpace = (value) => {
    if (!value) return null;
    const linesWithContent = value.split(/\n/).filter(line => line.replace(/\s/g, '').length);
    const linesExceptLast = linesWithContent.slice(0, -1);
    const lastLine = linesWithContent.pop();
    const extraSpace = value.match(/^(\s*)/)[0].length;
    const parsedResult = extraSpace ? linesExceptLast.map(line => line.slice(extraSpace - 1)) : linesExceptLast;
    return parsedResult.join('\n') + '\n' + lastLine.trim();
};

const parseCodeExamples = (string) => {
    const sections = string.split(/Input:|Output:|Failing Case:/);
    return {
        codeExamples: {
            passingCase: {
                input: removeExtraSpace(sections[1]),
                output: removeExtraSpace(sections[2])
            },
            failingCase: {
                input: removeExtraSpace(sections[4]),
                output: removeExtraSpace(sections[5])
            }
        }
    }
};

var parseTextWithLinksWithoutTitle = function(string) {
    if (!string) return null
    var parsedText = parseTextWithLinks(string);
    return parsedText.slice(1, parsedText.length);
};

var parseDescription = function(description) {
    var sections = description.split('\n\n');

    var sectionThatIncludes = function(text) {
        return sections.find(function(section) {
            return section.includes(text);
        });
    };

    return {
        long_description: parseTextWithLinks(description.split("Args:")[0]),
        args: parseField(sectionThatIncludes("Args:")),
        other_parameters: parseTextWithLinksWithoutTitle(sectionThatIncludes("Other Parameters:")),
        notes: parseField(sectionThatIncludes("Notes:"), parseTextWithLinks),
        see_also: parseField(sectionThatIncludes("See Also:"), linkParsing),
        example_data: parseExampleData(sectionThatIncludes("Example Data:")),
        code_examples: parseCodeExamples(sections.slice(-4).join('\n'))
    };
};

var camelCaseToSnakeCase = function(text) {
    return text.split(/(?=[A-Z])/).join('_').replaceAll(" ", "").toLowerCase();
};

var parseToPascalCase = function(text) {
    return text.replace(/\s+/g, '');
}

var parseExpectation = function(schema) {
    var getProperty = function(key) {
        const property = schema.properties.metadata.properties[key];
        if (key === "domain_type") {
            return property.description;
        }
        return property.const;
    };

    return {
        key: schema.title,
        title: parseToPascalCase(schema.title),
        link: camelCaseToSnakeCase(schema.title),
        description: parseDescription(schema.description),
        data_quality_issues: getProperty("data_quality_issues"),
        domain: getProperty("domain_type"),
        short_description: getProperty("short_description"),
        supported_data_sources: getProperty("supported_data_sources")
    };
};

var getExpectationsList = function() {
    return _.keys(jsonSchemas).map(function(expectationKey) {
        return parseExpectation(jsonSchemas[expectationKey].schema);
    });
};

module.exports = {
    getExpectationsList: getExpectationsList,
    parseExampleData: parseExampleData,
    removeExtraSpace: removeExtraSpace,
    linkParsing: linkParsing
};

